import { config } from 'config/tournaments';

import SmallBanner from 'images/Tournament/450x184-aviaRush.jpg';
import MediumBanner from 'images/Tournament/940x407-aviaRush.jpg';
import LargeBanner from 'images/Tournament/1441x407-aviaRush.jpg';
import Background from 'images/Tournament/1500x420-aviaRush.jpg';

export const IMAGES = {
  smallBannerUrl: SmallBanner,
  mediumBannerUrl: MediumBanner,
  largeBannerUrl: LargeBanner,
  backgroundUrl: Background,
};

export const STATUSES = {
  running: 'ACTIVE',
  comingSoon: 'PENDING',
  ended: 'FINISHED_VISIBLE',
};

export const getType = {
  [STATUSES.running]: 'running',
  [STATUSES.comingSoon]: 'comingSoon',
  [STATUSES.ended]: 'ended',
};

const categoryConfigs = {
  running: {
    title: 'Running',
    color: '#8ACB00',
  },
  comingSoon: {
    title: 'Coming soon',
    color: '#8ACB00',
  },
  ended: {
    title: 'Ended',
    color: '#EBEBEC',
  },
};

export const getCategory = (type) => categoryConfigs[type];

export const getGameUrl = (user_id, email) => {
  const { game_code, game_url } = config[process.env.REACT_APP_VERSION];

  return `${game_url}?user_id=${user_id}&game_code=${game_code}&email=${email}`;
};
