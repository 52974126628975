import * as XLSX from 'xlsx';
import { AsyncZipDeflate, Zip } from 'fflate';

import { recipeApi as gameService } from 'store/api/gameService/recipe.api';
import { show } from 'store/slices/alert';
import { initialState } from 'store/slices/allGames';
import {
  setModalOpened,
  zipGenerationEnd,
  zipGenerationStart,
} from 'store/slices/downloadProcess';

import { triggerPlausibleEvent } from 'utils/plausible';

import { materialsStructure } from 'components/config/games';
import { Languages, getLanguages } from 'components/config/languages';
import { plausibleEvents } from 'components/config/plausibleEvents';

const BATCH_SIZE = 5;
const MAX_ZIP_SIZE = 2 * 1024 ** 3; // 2GB;

export const saveAs = (url, fileName = '') => {
  const link = document.createElement('a');

  link.download = fileName;
  link.href = url;
  link.style.display = 'none';

  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const saveAsFile = (file, fileName, onSave = saveAs) => {
  const url = URL.createObjectURL(file);
  onSave(url, fileName);

  URL.revokeObjectURL(url);
};

export const fetchUrls = async (urls, onProgress, byChunks) => {
  if (!Array.isArray(urls)) {
    throw new Error('urls is not an Array');
  }

  const errors = [];

  const results = await Promise.all(
    urls.map(async (url) => {
      const response = await fetch(url);
      if (!response?.ok || !response?.body) {
        errors.push({ url });
        return null;
      }
      const contentLength = response.headers.get('Content-Length');
      const totalLength =
        typeof contentLength === 'string' && parseInt(contentLength);
      const reader = response.body.getReader();
      const chunks = [];
      let receivedLength = 0;

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        chunks.push(value);

        receivedLength += value.length;

        if (onProgress && typeof totalLength === 'number') {
          const step = Math.round(
            parseFloat((receivedLength / totalLength).toFixed(2)) * 100,
          );

          onProgress(url, step);
        }
      }

      let data = chunks;

      if (!byChunks) {
        data = new Uint8Array(receivedLength);
        let offset = 0;

        for (const chunk of chunks) {
          data.set(chunk, offset);
          offset += chunk.length;
        }
      }

      return { url, data };
    }),
  );

  return {
    results: results.filter(Boolean),
    errors,
  };
};

export const createUrlsFromStore = (store, structure, folderPath, type) => {
  const urls = [];
  const promoCategory = type === 'Promo' ? 'promo+pack/' : 'Promo+Pack/';

  if (store.wholeCategory.length) {
    store.wholeCategory.map((item) => {
      if (structure[item] && structure[item].file) {
        urls.push(`${folderPath}${structure[item].file}`);
      }
    });
  }

  if (Object.keys(store.category).length) {
    Object.keys(store.category).map((item) => {
      store.category[item].map((category) => {
        if (structure[item] && structure[item].subCategory) {
          if (
            Array.isArray(structure[item].subCategory) &&
            structure[item].subCategory.includes(category)
          ) {
            urls.push(`${folderPath}${item}/${category}.zip`);
          } else if (
            structure[item].subCategory[category] &&
            structure[item].subCategory[category].file
          ) {
            urls.push(
              `${folderPath}${promoCategory}${structure[item].subCategory[category].file}`,
            );
          }
        }
      });
    });
  }

  if (Object.keys(store.pack).length) {
    Object.keys(store.pack).map((item) => {
      Object.keys(store.pack[item]).map((category) => {
        store.pack[item][category].map((pack) => {
          urls.push(
            `${folderPath}${promoCategory}${category}/${pack}.zip`.replace(
              / /g,
              '+',
            ),
          );
        });
      });
    });
  }

  if (Object.keys(store.items).length) {
    Object.keys(store.items).map((item) => {
      Object.keys(store.items[item]).map((category) => {
        if (Array.isArray(store.items[item][category])) {
          store.items[item][category].map((el) => {
            urls.push(
              `${folderPath}${promoCategory}${category}/${el}.zip`.replace(
                / /g,
                '+',
              ),
            );
          });
        } else {
          Object.keys(store.items[item][category]).map((pack) => {
            store.items[item][category][pack].map((el) => {
              urls.push(
                `${folderPath}${promoCategory}${category}/${pack}/${el}.${pack.toLowerCase()}`.replace(
                  / /g,
                  '+',
                ),
              );
            });
          });
        }
      });
    });
  }

  return urls;
};

export const createXLSX = (sheetName, wb, ws) => {
  XLSX.utils.book_append_sheet(wb, ws, sheetName);

  const options = { bookType: 'xlsx', bookSST: false, type: 'binary' };
  const writeResult = XLSX.write(wb, options);

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  return s2ab(writeResult);
};

const gamesDataXLSX = (gamesData) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(
    gamesData.map((gameData) => ({
      name: gameData.name,
      ...Object.keys(gameData).reduce((acc, title) => {
        if (
          [
            'sliderLarge',
            'sliderSmall',
            'marker',
            'gameFeatures',
            'released',
            'private',
            'description',
            'name',
            'background',
            'logo',
            'icon',
            'media',
          ].includes(title)
        ) {
          return acc;
        }
        if (title === 'id' && gameData[title]?.length) {
          return {
            ...acc,
            'id Europe': gameData[title][0].id,
            'id Asia': gameData[title][1]
              ? gameData[title][1].id
              : gameData[title][0].id,
          };
        }
        return {
          ...acc,
          [title]: Array.isArray(gameData[title])
            ? gameData[title].join(',')
            : gameData[title],
        };
      }, {}),
    })),
  );

  return createXLSX('Game Data', wb, ws);
};

export const gamesDataDownload = async (filters, gamesData, addFileToZip) => {
  if (filters.wholeCategory.includes('game_data')) {
    await addFileToZip('Game Data.xlsx', gamesDataXLSX(gamesData));

    filters.wholeCategory = filters.wholeCategory.filter(
      (el) => el !== 'game_data',
    );
  }

  return filters;
};

const currencyXLSX = (gamesData, currencyList) => {
  console.log('gamesData', gamesData);
  console.log('currencyList', currencyList);
};

const currencyListDownload = (filters, gamesData) => {
  let currencyList = [];
  if (filters.wholeCategory.includes('currencies')) {
    currencyList = Object.keys(
      materialsStructure.currencies.subCategory,
    ).reduce(
      (acc, subCategory) => [
        ...acc,
        ...Object.keys(
          materialsStructure.currencies.subCategory[subCategory].items,
        ),
      ],
      [],
    );

    filters.wholeCategory = filters.wholeCategory.filter(
      (el) => el !== 'currencies',
    );
  } else {
    if (filters.category.currencies?.length) {
      filters.category.currencies.map((subCategory) => {
        currencyList = currencyList.concat(
          Object.keys(
            materialsStructure.currencies.subCategory[subCategory].items,
          ),
        );
      });

      filters.category = Object.keys(filters.category).reduce(
        (acc, key) =>
          key !== 'currencies' ? { ...acc, [key]: filters.category[key] } : acc,
        {},
      );
    }
    if (
      filters.pack.currencies &&
      Object.keys(filters.pack.currencies).length
    ) {
      Object.keys(filters.pack.currencies).map((pack) => {
        currencyList = currencyList.concat(filters.pack.currencies[pack]);
      });

      filters.pack = Object.keys(filters.pack).reduce(
        (acc, key) =>
          key !== 'currencies' ? { ...acc, [key]: filters.pack[key] } : acc,
        {},
      );
    }
  }

  if (currencyList.length) {
    currencyXLSX(gamesData, currencyList);
  }

  return filters;
};

const getFoldersPath = (type, gamesData, folderPath, generateFolderPath) => {
  if (['All', 'Selected'].includes(type)) {
    return gamesData.map(
      ({ gameCode }) =>
        generateFolderPath?.(gameCode) ?? `${folderPath}${gameCode}/`,
    );
  }
  return [folderPath];
};

const rulesListXLSX = (gamesData, response) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(
    response.map(({ name, lang }) => ({
      name: gamesData.find((game) => game.gameCode === name)?.name,
      ...Object.keys(lang).reduce(
        (acc, key) => ({ ...acc, [getLanguages(key)]: lang[key] }),
        {},
      ),
    })),
  );

  return createXLSX('Games Rules', wb, ws);
};

const gameRulesDownload = async (
  filters,
  gamesData,
  addFileToZip,
  dispatch,
) => {
  let rulesList = [];

  if (filters.wholeCategory.includes('rules')) {
    rulesList = materialsStructure.rules.subCategory;
    filters.wholeCategory = filters.wholeCategory.filter(
      (el) => el !== 'rules',
    );
  } else {
    if (filters.category.rules && filters.category.rules.length) {
      rulesList = filters.category.rules;
      filters.category = Object.keys(filters.category).reduce(
        (acc, key) =>
          key !== 'rules' ? { ...acc, [key]: filters.category[key] } : acc,
        {},
      );
    }
  }

  if (rulesList.length) {
    const rulesQuery = dispatch(
      gameService.endpoints.getRules.initiate({
        game_code: gamesData.map((game) => game.gameCode),
        lang: rulesList.map((lang) => Languages[lang]),
      }),
    );
    rulesQuery.unsubscribe();
    const responseRules = await rulesQuery;

    if (!responseRules?.data) {
      return {
        filters,
        error: 'query rules is empty',
      };
    }

    await addFileToZip(
      'Games Rules.xlsx',
      rulesListXLSX(gamesData, responseRules.data),
    );
  }

  return { filters, error: '' };
};

export const downloadMaterials = async (
  downloadFilters,
  folderPath,
  generateFolderPath,
  getState,
  dispatch,
  rejectWithValue,
  onProgress,
  onFinish,
  clearDialogData,
) => {
  try {
    const {
      downloadMaterials: { dialogData },
      allGames: { selectedItems },
    } = getState();

    let zip = null;
    let zipIndex = 0;
    let currentZipSize = 0;
    let chunks = [];
    let onFinalZip = null;

    const initializeZip = () => {
      zip = new Zip();
      zip.ondata = (err, chunk, final) => {
        if (err) {
          console.error('Zip compression error:', err);
          dispatch(show({ type: 'error', text: err.message ?? err }));
          onFinalZip?.();
          return;
        }

        if (chunk) chunks.push(chunk);
        if (final) {
          saveAsFile(
            new Blob(chunks, { type: 'application/zip' }),
            zipIndex
              ? `${dialogData.downloadFileName}_${zipIndex}.zip`
              : `${dialogData.downloadFileName}.zip`,
          );
          zipIndex++;
          currentZipSize = 0;
          chunks = [];

          onFinalZip?.();
        }
      };
    };

    const finalizeZip = async () =>
      new Promise((resolve) => {
        onFinalZip = resolve;
        zip.end();
      });

    const addFileToZip = async (path, data) => {
      const fileDeflate = new AsyncZipDeflate(path);
      zip.add(fileDeflate);

      if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          const chunk = data[i];

          currentZipSize += chunk.byteLength;

          fileDeflate.push(chunk, i === data.length - 1);

          if (i % 10 === 0) {
            await new Promise((resolve) => setTimeout(resolve, 0));
          }
        }

        return;
      }

      currentZipSize += data.byteLength;

      fileDeflate.push(new Uint8Array(data), true);
    };

    initializeZip();

    let responseAllGames = null;
    let gamesData = dialogData.gamesData;
    let filters = downloadFilters;

    if (
      dialogData.type === 'Game' &&
      ['All', 'Selected'].includes(dialogData.gamesData)
    ) {
      const queryAllGames = dispatch(
        gameService.endpoints.getGamesList.initiate({
          filters: initialState.filters,
          sort: initialState.sortBy,
          ...(dialogData.provider && { provider: dialogData.provider }),
        }),
      );
      queryAllGames.unsubscribe();
      responseAllGames = await queryAllGames;

      if (!responseAllGames?.data?.games) {
        return rejectWithValue('query all games is empty');
      }
    }

    if (dialogData.type === 'Game') {
      if (gamesData === 'All') {
        gamesData = responseAllGames?.data.games;
      } else if (gamesData === 'Selected' && selectedItems.length) {
        gamesData = responseAllGames?.data.games.filter((game) =>
          selectedItems.includes(game.gameCode),
        );
      }

      if (!Array.isArray(gamesData)) {
        return rejectWithValue('bad games data');
      }
    }

    filters = await gamesDataDownload(filters, gamesData, addFileToZip);
    filters = currencyListDownload(filters, gamesData);

    const { filters: filtersRules, error: errorRules } = gamesData
      ? await gameRulesDownload(filters, gamesData, addFileToZip, dispatch)
      : { filters, error: '' };

    if (errorRules) {
      return rejectWithValue(errorRules);
    }

    filters = filtersRules;

    const foldersPath = getFoldersPath(
      dialogData.gamesData,
      gamesData,
      folderPath,
      generateFolderPath,
    );

    let errors = [];

    const urls = foldersPath.flatMap((folder) =>
      createUrlsFromStore(
        filters,
        dialogData.structure,
        folder,
        dialogData.type,
      ),
    );

    dispatch(setModalOpened(true));

    const handleBatch = async (batchUrls) => {
      const { results, errors: batchErrors } = await fetchUrls(
        batchUrls,
        onProgress,
        true,
      );

      if (batchErrors.length) {
        errors.push(...batchErrors);
      }

      if (results.length) {
        dispatch(zipGenerationStart());

        for (const { url, data: chunks } of results) {
          let folders = url.split(folderPath)[1].split('/');

          if (Array.isArray(gamesData)) {
            const currentGame = gamesData.find(
              ({ gameCode }) => gameCode === folders[0],
            );

            if (currentGame) {
              folders[0] = currentGame.name;
            }
          }

          const path = folders.join('/').replace(/_/g, ' ').replace(/\+/g, ' ');

          await addFileToZip(path, chunks);

          if (currentZipSize >= MAX_ZIP_SIZE) {
            await finalizeZip();
            initializeZip();
          }
        }

        dispatch(zipGenerationEnd());
      }
    };

    for (let i = 0; i < urls.length; i += BATCH_SIZE) {
      const batch = urls.slice(i, i + BATCH_SIZE);
      await handleBatch(batch);
    }

    if (currentZipSize) {
      dispatch(zipGenerationStart());
      await finalizeZip();
      dispatch(zipGenerationEnd());
    }

    if (errors.length) {
      return rejectWithValue({
        errors: [...errors],
        name: dialogData.downloadFileName,
        folderPath,
      });
    }

    triggerPlausibleEvent({
      name: !dialogData.isAllGamesSelected
        ? plausibleEvents.successfulDownload
        : plausibleEvents.successfulDownloadAll,
      props: {
        page: dialogData.trackingPage,
      },
    });

    return { clearDialogData };
  } catch (e) {
    return rejectWithValue(e.message);
  } finally {
    onFinish();
  }
};
