import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { numberFormat } from 'utils/number';

import styles from 'components/pages/Tournaments/Item/Item.module.scss';

const propTypes = {
  termsAndConditions: PropTypes.string,
  placeAndPrize: PropTypes.arrayOf(
    PropTypes.shape({
      rewardedRanks: PropTypes.string,
      prize: PropTypes.string,
    }),
  ),
  prizesTotal: PropTypes.string,
  placeAndPrizeTotal: PropTypes.number,
};

const TermsAndPrize = ({
  termsAndConditions,
  placeAndPrize,
  placeAndPrizeTotal,
  prizesTotal,
}) => (
  <>
    {termsAndConditions && (
      <div className={styles.terms}>
        <div className={styles.title}>Terms and conditions</div>
        <div
          dangerouslySetInnerHTML={{
            __html: termsAndConditions,
          }}
        />
      </div>
    )}
    {placeAndPrize && (
      <div className={styles.prize}>
        <div className={styles.title}>
          {prizesTotal ? 'Prizes' : 'Place and prize allocations'}
        </div>
        <div className={styles.prizeContent}>
          <div className={styles.prizeContentTitle}>
            {prizesTotal ? 'Quantity' : 'Place'}
          </div>
          <div className={styles.prizeContentTitle}>Prize</div>
          {placeAndPrize.map(({ rewardedRanks, prize }) => (
            <React.Fragment key={`${rewardedRanks}${prize}`}>
              <div className={styles.prizeContentOption}>{rewardedRanks}</div>
              <div className={styles.prizeContentOption}>{prize}</div>
            </React.Fragment>
          ))}

          {placeAndPrizeTotal && (
            <div
              className={classNames(styles.prizeContentFooter, {
                [styles.footerPrize]: prizesTotal,
              })}
            >
              <div className={styles.prizeContentFooterTotal}>
                Total {numberFormat(placeAndPrizeTotal)} EUR
              </div>
            </div>
          )}
        </div>
      </div>
    )}
  </>
);

TermsAndPrize.propTypes = propTypes;
export default TermsAndPrize;
